// export const API_URL = `${process.env.API_URL}`.replace(/^(.*)\/+$/, "$1");

export function resolveVueUrl(host: string, protocol: string = "https") {
    const regexRootDomain = /^app\.(.*)$/;

    if (regexRootDomain.test(host)) {
        const vueHost = host.replace(regexRootDomain, "vue.$1");

        return `${protocol}//${vueHost}`;
    }

    const regExpPreviewApps = /^(.*)\.(preview-apps\.pillar\.science)$/;

    if (regExpPreviewApps.test(host)) {
        return host.replace(regExpPreviewApps, `${protocol}//$1-vue.$2`);
    }

    const regExp = /^(.*)\.(pillar\.science)$/;

    if (regExp.test(host)) {
        return host.replace(regExp, `${protocol}//$1.vue.$2`);
    }

    return `${protocol}//localhost:8081`;
}

export const DEFAULT_DEBOUNCE = 200;
export const DEBOUNCE_MID = 600;
export const ACCOUNT_EXPIRATION_THRESHOLD_DAYS = 30;
